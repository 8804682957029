import React from "react";
import ReactDOM from "react-dom/client";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {isMobile} from 'react-device-detect';

import moment from 'moment/min/moment-with-locales'
moment.locale('ru');

const importDir = !isMobile ? 'desktop' : 'mobile';
const Layout = React.lazy(() => import(`./pages/${importDir}/Layout`));
const Home = React.lazy(() => import(`./pages/${importDir}/Home`));
const Search = React.lazy(() => import(`./pages/${importDir}/Search`));
const Agreement = React.lazy(() => import(`./pages/${importDir}/Agreement`));
const PrivacyPolicy = React.lazy(() => import(`./pages/${importDir}/PrivacyPolicy`));

Number.prototype.formatSeats = function(showNumber = true) {
    let seats = this.valueOf();
    let text = '';
    if(seats === 0 || seats >= 5) text = 'мест';
    if(seats >= 2 && seats <= 4) text = 'места';
    if(seats === 1) text = 'место';
    if(seats >= 11 && seats <= 19) text = 'мест';
    if(showNumber) return `${seats} ${text}`;
    return `${text}`;
};

String.prototype.capitalize = function () {
    const string = this.valueOf();
    return string.charAt(0).toUpperCase() + string.slice(1)
};

Number.prototype.formatPrice = function(_currency, n, x) {
    const re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\.' : '$') + ')';
    let currency = _currency ? _currency : 'EUR';
    let price = this.toFixed(Math.max(0, ~~n)).replace(new RegExp(re, 'g'), '$& ');
    if(currency === 'EUR') return `${price} €`;
    if(currency === 'USD') return `${price} $`;
    if(currency === 'GBP') return `${price} £`;
    if(currency === 'RUB') return `${price} ₽`;
};

export default function App() {
    return (
        <BrowserRouter>
            <React.Suspense>
                <Routes>
                    <Route path="/" element={<Layout/>}>
                        <Route index element={<Home/>}/>
                        <Route path="search" element={<Search/>}/>
                        <Route path="agreement" element={<Agreement/>}/>
                        <Route path="privacy-policy" element={<PrivacyPolicy/>}/>
                        <Route path="*" element={<Home/>} />
                    </Route>
                </Routes>
            </React.Suspense>
        </BrowserRouter>
    );
}

const container = document.getElementById('root');
const root = ReactDOM.createRoot(container);
root.render(<App/>);
